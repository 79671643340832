export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/(authenticated)/admin/areas": [5,[2,3]],
		"/(authenticated)/admin/details": [6,[2,3]],
		"/(authenticated)/admin/equipment": [7,[2,3]],
		"/(authenticated)/admin/equipment/[id]": [8,[2,3]],
		"/(authenticated)/admin/exercises": [9,[2,3]],
		"/(authenticated)/admin/exercises/[id]": [10,[2,3]],
		"/(authenticated)/admin/users": [11,[2,3]],
		"/(authenticated)/dashboard": [12,[2]],
		"/(gym)/equipment/[id]": [26],
		"/(gym)/exercises": [28],
		"/(gym)/exercise/[id]": [27],
		"/gyms": [29],
		"/(authenticated)/home": [13,[2]],
		"/(authenticated)/logout": [14,[2]],
		"/(authenticated)/nutrition": [15,[2]],
		"/(authenticated)/preferences": [16,[2]],
		"/(authenticated)/profile": [17,[2]],
		"/(authenticated)/progress": [18,[2]],
		"/(authenticated)/search": [19,[2]],
		"/(authenticated)/session/[id]": [20,[2]],
		"/(authenticated)/stats": [21,[2]],
		"/(authenticated)/stats/[id]": [22,[2]],
		"/(authenticated)/user/[id]": [23,[2]],
		"/(authenticated)/workouts": [24,[2]],
		"/(authenticated)/workouts/[id]": [25,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';